@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

*:hover {
  transition-duration: 0.3s;
}

html {
scroll-behavior: smooth;
background-color: var(--white);
}

/* Typography */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
a, p, span, del, s, ins, u,
small, strong, em,
.display-1, .display-2, .display-3, .display-4 {
font-family: "Helvetica Neue", Helvetica, "Inter", Arial, sans-serif;
font-size: 1rem;
color: var(--body) !important;

leading-trim: both;
text-edge: cap;
}

h1,
.h1 {
font-style: normal;
font-weight: 500;
font-size: 2.5rem;
line-height: 120%;
}

h2,
.h2 {
font-style: normal;
font-weight: 500;
font-size: 2rem;
line-height: 130%;
}

h3,
.h3 {
font-style: normal;
font-weight: 500;
font-size: 1.75rem;
line-height: 130%;
}

h4,
.h4 {
font-style: normal;
font-weight: 500;
font-size: 1.5rem;
line-height: 140%;
}

h5,
.h5 {
font-style: normal;
font-weight: 500;
font-size: 1.25rem;
line-height: 140%;
}

h6,
.h6 {
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 150%;
}

a:hover,
a.text-primary:hover {
  text-decoration: underline;
  /* color: var(--primary) !important; */
}

.display-1 {
font-style: normal;
font-weight: 300;
font-size: 6rem;
line-height: 120%;
}

.display-2 {
font-style: normal;
font-weight: 300;
font-size: 5.5rem;
line-height: 120%;
}

.display-3 {
font-style: normal;
font-weight: 300;
font-size: 4.5rem;
line-height: 120%;
}

.display-4 {
font-style: normal;
font-weight: 300;
font-size: 3.5rem;
line-height: 120%;
}

b,
strong {
font-style: normal;
font-weight: bold;
font-size: 1rem;
line-height: 150%;
}

ins,
u {
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 150%;
text-decoration-line: underline;
}

del,
s {
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 150%;
text-decoration-line: line-through;
}

small,
.small {
font-style: normal;
font-weight: normal;
font-size: 0.8rem;
line-height: 150%;
}

p {
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 150%;

white-space: pre-line;  
vertical-align: bottom;
}

.condensed {
  font-variation-settings: 'wdth' 75;
}

.font-weight-400 { font-weight: 400 !important; }
.font-weight-500 { font-weight: 500 !important; }
.font-weight-600 { font-weight: 600 !important; }

/* Colors */
:root {
  --primary: #007BFF;
  --secondary: #6C757D;
  --success: #28A745;
  --danger: #dc3545;
  --warning: #ffc107;
  --info: #465362;
  --light: #f4fffd;
  --dark: #26282b;
  --white: #ffffff;
  --transparent: ;
  --border: #DEE2E6;

  --body: #212529;
  --muted: #6C757D;
  --black: #000000;
}

.border-primary {
border-color: var(--primary) !important;
}

.text-primary {
color: var(--primary) !important;
}

.text-secondary,
a.text-secondary,
a.text-secondary:hover {
color: var(--secondary) !important;
}

.text-success {
color: var(--success) !important;
}

.text-danger {
color: var(--danger) !important;
}

.text-warning {
color: var(--warning) !important;
}

.text-info {
color: var(--info) !important;
}

.text-light {
color: var(--light) !important;
}

.text-dark {
color: var(--dark) !important;
}

.text-body {
color: var(--body) !important;
}

.text-muted {
  color: var(--muted) !important;
}

.bg-primary {
background-color: var(--primary) !important;
}

.bg-secondary {
background-color: var(--secondary) !important;
}

.bg-success {
background-color: var(--success) !important;
}

.bg-danger {
background-color: var(--danger) !important;
}

.bg-warning {
background-color: var(--warning) !important;
}

.bg-info {
background-color: var(--info) !important;
}

.bg-light {
background-color: var(--light) !important;
}

.bg-dark {
background-color: var(--dark) !important;
}

.bg-white {
background-color: var(--white) !important;
}

.bg-black {
background-color: var(--black) !important;
}

/* Buttons */
.btn:hover {
opacity: 0.8;
}

.btn-black {
background-color: var(--black) !important;
}

/* Badge */
.badge-primary {
  background-color: var(--primary) !important;
}

.badge-info {
  background-color: var(--info) !important;
}

/* Header */
.brand:hover,
.nav-item:hover {
opacity: 0.8;
}

/* Projects */
.project-listing {
display: flex;
flex-direction: column;
gap: 6.25rem;
}

.project-item {
border-radius: 16px;
}

.project-item * {
transition-duration: 0.5s;
}

.project-item .img-container img,
.project-banner .img-container img {
  left: 0;
}

.project-item:hover .img-container img {
scale: 1.05;
/* transition-duration: 0.3s; */
}

.project-item .img-container,
.project-banner .img-container {
position: relative;
width: 100%;
padding-bottom: 66.6%;
}

.project-item .description,
.project-banner .description {
font-size: 1.25rem;
}

.project-info .title { 
font-size: 0.75rem; 
}

.project-info .description { font-size: 1rem; }

.iframe-container { 
width: 100%; 
padding-bottom: 66.6%;
}

.paragraph:last-of-type {
  padding-bottom: 0 !important;
}

.img-fw-container { margin-bottom: 2rem; }
.img-fw-container:last-of-type { margin-bottom: 0; }

/* AOS override */
:root {
--animations-distance: 50px;
}

[data-aos=fade-up] {
transform: translate3d(0,var(--animations-distance),0)
}

[data-aos=fade-down] {
transform: translate3d(0,-var(--animations-distance),0)
}

[data-aos=fade-right] {
transform: translate3d(-var(--animations-distance),0,0)
}

[data-aos=fade-left] {
transform: translate3d(var(--animations-distance),0,0)
}

[data-aos=fade-up-right] {
transform: translate3d(-var(--animations-distance),var(--animations-distance),0)
}

[data-aos=fade-up-left] {
transform: translate3d(var(--animations-distance),var(--animations-distance),0)
}

[data-aos=fade-down-right] {
transform: translate3d(-var(--animations-distance),-var(--animations-distance),0)
}

[data-aos=fade-down-left] {
transform: translate3d(var(--animations-distance),-var(--animations-distance),0)
}

[data-aos=zoom-in-up] {
transform: translate3d(0,var(--animations-distance),0) scale(.6)
}

[data-aos=zoom-in-down] {
transform: translate3d(0,-var(--animations-distance),0) scale(.6)
}

[data-aos=zoom-in-right] {
transform: translate3d(-var(--animations-distance),0,0) scale(.6)
}

[data-aos=zoom-in-left] {
transform: translate3d(var(--animations-distance),0,0) scale(.6)
}

[data-aos=zoom-out-up] {
transform: translate3d(0,var(--animations-distance),0) scale(1.2)
}

[data-aos=zoom-out-down] {
transform: translate3d(0,-var(--animations-distance),0) scale(1.2)
}

[data-aos=zoom-out-right] {
transform: translate3d(-var(--animations-distance),0,0) scale(1.2)
}

[data-aos=zoom-out-left] {
transform: translate3d(var(--animations-distance),0,0) scale(1.2)
}

[data-aos=slide-up] {
transform: translate3d(0,100%,0)
}

[data-aos=slide-down] {
transform: translate3d(0,-100%,0)
}

[data-aos=slide-right] {
transform: translate3d(-100%,0,0)
}

[data-aos=slide-left] {
transform: translate3d(100%,0,0)
}

.csc {
  overflow: hidden;
}

/* .scroll-container *,
.scroll-container *:hover {
  transition-duration: 0s !important;
}


.scroll-sequence__container {
  position: sticky;
}

.speak {
  padding: 150vh 0;
}
.speak:first-child {
  margin-top: -50vh;
}

.speak[data-scroll] {
  transform: translatey(calc(var(--viewport-y) * 10vh));
  opacity: calc(var(--visible-y));
}

.scroll-sequence__content {
  padding: 2em;
}

.scroll-sequence {
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */